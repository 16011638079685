<template>
  <svg
    height="84"
    viewBox="0 0 1366 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    aria-hidden="true"
  >
    <path
      d="M546.8 62.9199C732.446 82.0402 1170.27 87.4037 1367 80.2026L1367 0.270622L7.25608e-06 0.270503L4.8009e-06 28.3545C105.599 26.9143 358.024 43.4772 546.8 62.9199Z"
      fill="currentColor"
    />
  </svg>
</template>
